var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "b-card-forms" },
    [
      _c(
        "b-row",
        { staticClass: "vh-100", attrs: { "align-h": "center" } },
        [
          _c(
            "b-col",
            { attrs: { "align-self": "center", cols: "12", sm: "9", lg: "5" } },
            [
              _vm.verificationEmailSuccess
                ? _c(
                    "b-card",
                    { staticClass: "text-center" },
                    [
                      _c("img", {
                        staticClass: "webmag-logo-big",
                        attrs: {
                          src: require("@/assets/logo-webmag-big.png"),
                          alt: "webmag logo"
                        }
                      }),
                      _c("p", [_vm._v(_vm._s(_vm.$t("verify.thankYou")))]),
                      _c("p", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("verify.emailVerifySuccessMessage")
                          )
                        }
                      }),
                      _c(
                        "b-button",
                        {
                          staticClass: "p-3",
                          attrs: {
                            block: "",
                            to: { path: "/" },
                            variant: "success"
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("verify.startNowButton")) + " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showResetPasswordForm
                ? _c(
                    "b-card",
                    { staticClass: "text-center" },
                    [
                      _c("img", {
                        staticClass: "webmag-logo-big",
                        attrs: {
                          src: require("@/assets/logo-webmag-big.png"),
                          alt: "webmag logo"
                        }
                      }),
                      _c("password-reset-form", {
                        attrs: { "action-code": _vm.actionCode }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.verificationError
                ? _c(
                    "b-card",
                    { staticClass: "text-center error" },
                    [
                      _c("img", {
                        staticClass: "webmag-logo-big",
                        attrs: {
                          src: require("@/assets/logo-webmag-big.png"),
                          alt: "webmag logo"
                        }
                      }),
                      _c(
                        "b-alert",
                        {
                          attrs: {
                            variant: "danger",
                            show: _vm.showErrorMessage
                          }
                        },
                        [_vm._v(_vm._s(_vm.errorMsg))]
                      ),
                      _c("p", [
                        _vm._v(_vm._s(_vm.$t("verify.verifyErrorMessage")))
                      ]),
                      _c(
                        "b-button",
                        {
                          staticClass: "p-3",
                          attrs: { block: "", variant: "success" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("verify.contactAdminButton")) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.verificationSuccessLogoutState
                ? _c(
                    "b-card",
                    { staticClass: "text-center error" },
                    [
                      _c("img", {
                        staticClass: "webmag-logo-big",
                        attrs: {
                          src: require("@/assets/logo-webmag-big.png"),
                          alt: "webmag logo"
                        }
                      }),
                      _c("p", [_vm._v(_vm._s(_vm.$t("verify.thankYou")))]),
                      _c("p", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("verify.emailVerifySuccessMessage")
                          )
                        }
                      }),
                      _c(
                        "b-button",
                        {
                          staticClass: "p-3",
                          attrs: {
                            block: "",
                            to: {
                              path: "/webmag-login",
                              query: { email: _vm.activationEmail }
                            },
                            variant: "success"
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("verify.loginNowButton")) + " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }