var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { attrs: { show: _vm.$apollo.loading } },
    [
      _vm.currentItemObject &&
      _vm.currentItemObject.incompatibleItem &&
      _vm.userObject &&
      !_vm.userObject.isSysAdmin
        ? _c("modal-incompatible-item", {
            attrs: {
              "item-name": _vm.currentItemObject.name,
              "modal-for-detail-page": true
            }
          })
        : !_vm.$apollo.loading &&
          _vm.currentItemObject &&
          _vm.$hasPrivileges(
            _vm.$privileges("CAN_EDIT_PROJECT_SETTINGS"),
            _vm.userObject.isSysAdmin,
            _vm.currentItemObject.privilegesForItem
          )
        ? _c(
            "b-tabs",
            {
              attrs: {
                vertical: "",
                "nav-wrapper-class": "col-3 bd-sidebar",
                "nav-class": "mt-5"
              },
              scopedSlots: _vm._u([
                {
                  key: "tabs-start",
                  fn: function() {
                    return [
                      _c("h3", [_vm._v(_vm._s(_vm.$t("settings.settings")))])
                    ]
                  },
                  proxy: true
                }
              ]),
              model: {
                value: _vm.tabIndex,
                callback: function($$v) {
                  _vm.tabIndex = $$v
                },
                expression: "tabIndex"
              }
            },
            [
              _vm.$hasPrivileges(
                _vm.$privileges("CAN_EDIT_SETTINGS_PUBLICATION"),
                _vm.userObject.isSysAdmin,
                _vm.currentItemObject.privilegesForItem
              )
                ? _c(
                    "b-tab",
                    {
                      on: {
                        click: function($event) {
                          return _vm.setUrl("")
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "settings.project.publications.tabName"
                                    )
                                  )
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        155488480
                      )
                    },
                    [
                      _vm.userObject
                        ? _c("project-settings-publications", {
                            staticClass: "mt-5",
                            attrs: {
                              "is-sys-admin": _vm.userObject.isSysAdmin,
                              "publication-data-prop": _vm.publicationData,
                              "sibling-slugs": _vm.allChildSlugsOfParentFolder,
                              "project-tags": _vm.projectTags,
                              "all-tags": _vm.allTagsOfGroup
                            },
                            on: {
                              updatePublicationSettings:
                                _vm.updatePublicationSettings
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$hasPrivileges(
                _vm.$privileges("CAN_EDIT_SETTINGS_CI"),
                _vm.userObject.isSysAdmin,
                _vm.currentItemObject.privilegesForItem
              )
                ? _c(
                    "b-tab",
                    {
                      ref: _vm.$t("settings.project.corporateIdentity.hash"),
                      on: {
                        click: function($event) {
                          _vm.setUrl(
                            _vm.$t("settings.project.corporateIdentity.hash")
                          )
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "settings.project.corporateIdentity.tabName"
                                    )
                                  )
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        2925437210
                      )
                    },
                    [
                      _c("project-settings-corporate-identity", {
                        staticClass: "mt-5",
                        attrs: {
                          "corporate-identity-data": _vm.corporateIdentity,
                          "custom-group-fonts": _vm.customGroupFonts,
                          "is-sys-admin": _vm.userObject.isSysAdmin
                        },
                        on: { "update-ci-settings": _vm.updateCISettings }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$hasPrivileges(
                _vm.$privileges("CAN_EDIT_SETTINGS_EMBED"),
                _vm.userObject.isSysAdmin,
                _vm.currentItemObject.privilegesForItem
              )
                ? _c(
                    "b-tab",
                    {
                      ref: _vm.$t("settings.project.embedded.hash"),
                      on: {
                        click: function($event) {
                          _vm.setUrl(_vm.$t("settings.project.embedded.hash"))
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("settings.project.embedded.tabName")
                                  )
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        3826408417
                      )
                    },
                    [
                      _c("project-settings-embedded", {
                        staticClass: "mt-5",
                        attrs: {
                          "project-id": _vm.currentItemObject.id,
                          "group-slug": _vm.groupSlug,
                          "project-name": _vm.projectName,
                          "group-domain": _vm.groupDomain,
                          "group-id": _vm.groupId,
                          name: _vm.publicationData.name,
                          "publication-data-prop": _vm.publicationData
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$hasPrivileges(
                _vm.$privileges("CAN_EDIT_SETTINGS_NAVIGATION"),
                _vm.userObject.isSysAdmin,
                _vm.currentItemObject.privilegesForItem
              )
                ? _c(
                    "b-tab",
                    {
                      ref: _vm.$t("settings.project.projectType.hash"),
                      on: {
                        click: function($event) {
                          _vm.setUrl(
                            _vm.$t("settings.project.projectType.hash")
                          )
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "settings.project.projectType.tabName"
                                    )
                                  )
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        1628454658
                      )
                    },
                    [
                      _vm.groupId
                        ? _c("project-settings-project-type", {
                            staticClass: "mt-5",
                            attrs: {
                              "theme-id": _vm.themeId,
                              "group-id": _vm.groupId,
                              "theme-settings": _vm.themeSettings
                            },
                            on: {
                              "update-theme-settings": _vm.updateThemeSettings
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$hasPrivileges(
                _vm.$privileges("CAN_EDIT_SETTINGS_PLUGINS"),
                _vm.userObject.isSysAdmin,
                _vm.currentItemObject.privilegesForItem
              )
                ? _c(
                    "b-tab",
                    {
                      ref: _vm.$t("settings.plugins.hash"),
                      on: {
                        click: function($event) {
                          _vm.setUrl(_vm.$t("settings.plugins.hash"))
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _vm._v(
                                  _vm._s(_vm.$t("settings.plugins.tabName"))
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        464170190
                      )
                    },
                    [
                      _c("plugin-list", {
                        key: _vm.componentKey,
                        staticClass: "mt-5",
                        attrs: {
                          "project-id": _vm.currentItemObject.id,
                          "is-sys-admin": _vm.userObject.isSysAdmin,
                          privileges: _vm.currentItemObject.privilegesForItem,
                          childPageSlugs: _vm.allChildSlugsOfProject
                        },
                        on: {
                          "update-plugin-settings": _vm.updatePluginSettings
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$hasPrivileges(
                _vm.$privileges("CAN_EDIT_CUSTOM_CSS"),
                _vm.userObject.isSysAdmin,
                _vm.currentItemObject.privilegesForItem
              )
                ? _c(
                    "b-tab",
                    {
                      ref: _vm.$t("settings.customCss.hash"),
                      attrs: { lazy: "" },
                      on: {
                        click: function($event) {
                          _vm.setUrl(_vm.$t("settings.customCss.hash"))
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [_vm._v("Custom CSS")]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        781904493
                      )
                    },
                    [
                      _c(
                        "b-row",
                        { staticClass: "mb-4 mt-5" },
                        [
                          _c(
                            "b-col",
                            {
                              attrs: { cols: "12", "offset-sm": "1", sm: "10" }
                            },
                            [
                              _c("h3", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("settings.customCss.tabTitle")
                                    ) +
                                    " "
                                )
                              ]),
                              _c("hr"),
                              _c("p", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("settings.customCss.tabDesc")
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mb-4 pb-4" },
                        [
                          _c(
                            "b-col",
                            {
                              attrs: { cols: "12", "offset-sm": "1", sm: "10" }
                            },
                            [
                              _c("label", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "settings.customCss.customCssLabel"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          ),
                          _c(
                            "b-col",
                            {
                              attrs: { cols: "12", "offset-sm": "1", sm: "10" }
                            },
                            [
                              _c("custom-css-editor", {
                                attrs: { cssCode: _vm.customCss },
                                on: { "update-cssCode": _vm.updateCustomCss }
                              }),
                              _c("b-form-text", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("settings.customCss.customCssDesc")
                                    ) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-tab",
                {
                  ref: _vm.$t("settings.analytics.hash"),
                  attrs: { lazy: "" },
                  on: {
                    click: function($event) {
                      _vm.setUrl(_vm.$t("settings.analytics.hash"))
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _vm._v(
                            _vm._s(
                              _vm.$t("settings.analytics.usefathomTabName")
                            )
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _vm.slugPathFromUrl &&
                  _vm.groupSlug &&
                  _vm.groupDomain &&
                  _vm.currentItemObject
                    ? _c("usefathom-analytics-dashboard", {
                        attrs: {
                          "group-domain": _vm.groupDomain,
                          "group-slug": _vm.groupSlug,
                          "slug-path-from-url": _vm.slugPathFromUrl,
                          "item-id": _vm.currentItemObject.id,
                          "item-type": "project",
                          showReadersInTable: _vm.showReadersInTable,
                          isSysAdmin: _vm.userObject.isSysAdmin
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm.userObject.isSysAdmin
                ? _c(
                    "b-tab",
                    {
                      ref: "pirsch",
                      attrs: { lazy: "" },
                      on: {
                        click: function($event) {
                          return _vm.setUrl("pirsch")
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("settings.analytics.pirschTabName")
                                  )
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        676251697
                      )
                    },
                    [
                      _vm.currentItemObject && _vm.pirschCode
                        ? _c("pirsch-analytics-dashboard", {
                            attrs: {
                              "item-id": _vm.currentItemObject.id,
                              "item-type": "project",
                              pirschCode: _vm.pirschCode,
                              showReadersInTable: _vm.showReadersInTable,
                              isSysAdmin: _vm.userObject.isSysAdmin
                            }
                          })
                        : !_vm.pirschCode
                        ? _c(
                            "div",
                            [
                              _c(
                                "b-row",
                                { staticClass: "row mb-4 mt-5" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        "offset-sm": "1",
                                        sm: "10"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "settings.analytics.noPirschCode"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.userObject.isSysAdmin
                ? _c(
                    "b-tab",
                    {
                      ref: "stats",
                      attrs: { lazy: "" },
                      on: {
                        click: function($event) {
                          return _vm.setUrl("stats")
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _vm._v(
                                  _vm._s(_vm.$t("settings.analytics.tabName"))
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        3916627714
                      )
                    },
                    [
                      _vm.slugPathFromUrl &&
                      _vm.groupDomain &&
                      _vm.currentItemObject &&
                      _vm.pirschCode
                        ? _c("merged-analytics-dashboard", {
                            attrs: {
                              "group-domain": _vm.groupDomain,
                              "slug-path-from-url": _vm.slugPathFromUrl,
                              "item-id": _vm.currentItemObject.id,
                              "item-type": _vm.currentItemObject.type,
                              "pirsch-code": _vm.pirschCode
                            }
                          })
                        : !_vm.pirschCode
                        ? _c(
                            "div",
                            [
                              _c(
                                "b-row",
                                { staticClass: "row mb-4 mt-5" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        "offset-sm": "1",
                                        sm: "10"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "settings.analytics.noPirschCode"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.hasGroupPrivileges(
                _vm.$privileges("CAN_CREATE_PDF"),
                _vm.groupPrivileges,
                _vm.userObject.isSysAdmin
              )
                ? _c(
                    "b-tab",
                    {
                      ref: _vm.$t("settings.pdf.hash"),
                      attrs: { lazy: "" },
                      on: {
                        click: function($event) {
                          _vm.setUrl(_vm.$t("settings.pdf.hash"))
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _vm._v(_vm._s(_vm.$t("settings.pdf.tabTitle")))
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        882667969
                      )
                    },
                    [
                      _c("pdf-export", {
                        attrs: { "project-id": _vm.currentItemObject.id }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c("modal-leave-page", { ref: "leaveModalWindow" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }