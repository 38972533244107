import gql from 'graphql-tag';

export default gql`
mutation updateEmailConfirmation(
  $firebaseId: String!,
  $active: Boolean!,
  $emailConfirmed: Boolean!
) {
  update_users(
    where: {
      firebase_id: {_eq: $firebaseId}
    }, 
    _set: { email_confirmed: $emailConfirmed, active: $active }
  ) {
    affected_rows
  }
}
`;
