<template>
  <b-container class="b-card-forms">
    <b-row class="vh-100" align-h="center">
      <b-col align-self="center" cols="12" sm="9" lg="5">
        <b-card class="text-center" v-if="verificationEmailSuccess">
          <img class="webmag-logo-big" src="@/assets/logo-webmag-big.png" alt="webmag logo"/>
          <p>{{ $t('verify.thankYou') }}</p>
          <p v-html="$t('verify.emailVerifySuccessMessage')"></p>
          <b-button
            block
            class="p-3"
            :to="{ path: '/' }"
            variant="success"
          >
            {{ $t('verify.startNowButton') }}
          </b-button>
        </b-card>
        <b-card class="text-center" v-if="showResetPasswordForm">
          <img class="webmag-logo-big" src="@/assets/logo-webmag-big.png" alt="webmag logo"/>
          <password-reset-form :action-code="actionCode"/>
        </b-card>
        <b-card class="text-center error" v-if="verificationError">
          <img class="webmag-logo-big" src="@/assets/logo-webmag-big.png" alt="webmag logo"/>
          <b-alert variant="danger" :show="showErrorMessage">{{ errorMsg }}</b-alert>
          <p>{{ $t('verify.verifyErrorMessage') }}</p>
          <b-button
            block
            class="p-3"
            variant="success"
          >
            {{ $t('verify.contactAdminButton') }}
          </b-button>
        </b-card>
        <b-card class="text-center error" v-if="verificationSuccessLogoutState">
          <img class="webmag-logo-big" src="@/assets/logo-webmag-big.png" alt="webmag logo"/>
          <p>{{ $t('verify.thankYou') }}</p>
          <p v-html="$t('verify.emailVerifySuccessMessage')"></p>
          <b-button
            block
            class="p-3"
            :to="{ path: '/webmag-login', query: { email: activationEmail } }"
            variant="success"
          >
            {{ $t('verify.loginNowButton') }}
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import SetPageTypeInCache from '@/graphQlQueries/mutations/setPageTypeInCache';
import auth from '@/plugins/firebase';
import SetUserActive from '@/graphQlQueries/mutations/setUserActive';

export default {
  name: 'Verify',
  components: {
    PasswordResetForm: () => import('@/components/forms/ResetPasswordForm.vue'),
  },
  data: () => ({
    loading: true,
    mode: null,
    actionCode: null,
    activationEmail: null,
    continueUrl: null,
    lang: null,
    verificationError: false,
    verificationEmailSuccess: false,
    showResetPasswordForm: false,
    verificationSuccessLogoutState: false,
    errorMsg: null,
  }),
  computed: {
    showErrorMessage() {
      return !!this.errorMsg;
    },
  },
  created() {
    this.mode = (this.$route.query.mode) ? this.$route.query.mode : null;
    this.actionCode = (this.$route.query.oobCode) ? this.$route.query.oobCode : null;
    this.continueUrl = (this.$route.query.continueUrl) ? this.$route.query.continueUrl : null;
    this.lang = (this.$route.query.lang) ? this.$route.query.continueUrl : 'en';
    switch (this.mode) {
      case 'verifyEmail':
        // Display email verification handler and UI.
        this.handleVerifyEmail();
        break;
      case 'resetPassword':
        // handleResetPassword(auth, actionCode, continueUrl, lang);
        this.handleResetPassword();
        break;
      default:
        // Error: invalid mode.
        this.verificationError = true;
        this.loading = false;
    }
    this.$apollo.mutate({
      mutation: SetPageTypeInCache,
      variables: {
        type: 'verify-page',
      },
    });
  },
  methods: {
    goToApp() {
      this.$nextTick(() => {
        this.$router.push('/').catch((err) => {
          throw new Error(`Routing Error after verify occurred! ${err}.`);
        });
      });
    },
    handleResetPassword() {
      // Verify the password reset code is valid.
      auth.verifyPasswordResetCode(this.actionCode).then(() => {
        this.showResetPasswordForm = true;
      }).catch((error) => {
        // Invalid or expired action code. Ask user to try to reset the password again.
        console.log(error);
      });
    },
    handleVerifyEmail() {
      this.verificationError = false;
      // Try to apply the email verification code.
      auth.checkActionCode(this.actionCode).then((metaData) => {
        this.activationEmail = metaData.data.email;
        auth.applyActionCode(this.actionCode)
          .then(() => {
            // Email address has been verified.
            if (auth.currentUser) {
              // make the mutation of the hasura user table
              this.$apollo.mutate({
                mutation: SetUserActive,
                variables: {
                  firebaseId: auth.currentUser.uid,
                  active: true,
                  emailConfirmed: true,
                },
              }).then(() => {
                auth.currentUser.reload().then(() => {
                  this.verificationEmailSuccess = true;
                });
              });
            } else {
              // user is not logged in
              // show message to login form
              this.verificationSuccessLogoutState = true;
            }
          })
          .catch((err) => {
            // Code is invalid or expired
            this.verificationError = true;
            this.errorMsg = err.message;
            console.log('errror', err.message);
          });
      });
      this.loading = false;
    },
  },
};
</script>
