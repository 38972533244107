import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';

import firebaseConfig from '@/config/firebaseConfig.json';

firebase.initializeApp(firebaseConfig);
firebase.analytics();

const auth = firebase.auth();

export default auth;
